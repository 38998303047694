.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 0px;
  color: var(--white);
  min-height: 100px;

  @media (max-width: 700px) {
    min-height: 20px;
    align-items: center;
  }
}

.header-name-button {
  min-width: 20%;
  z-index: 102;
  display: flex;
  justify-content: left;
  @media (max-width: 700px) {
    align-items: center;
    justify-content: center;
  }
}

.header-name {
  font-size: 24px;
  font-family: "Oxygen";

  @media (max-width: 700px) {
    font-size: 16px;
  }
}

.header-menu {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  gap: 50px;
}

.header-menu-mobile {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  gap: 40px;
  background-color: var(--black);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 101;
  overflow: hidden;
  height: 0%;
}

.header-menu-mobile.visible {
  animation: header-menu-mobile-showing 1s;
  animation-fill-mode: forwards;
}

.header-menu-mobile.hidden {
  animation: header-menu-mobile-hiding 1s;
  animation-fill-mode: forwards;
}

@keyframes header-menu-mobile-showing {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes header-menu-mobile-hiding {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

.header-language {
  align-self: flex-start;
  min-width: 20%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.dark {
  color: var(--black);
}

.header-menu-mobile-flex-container {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.header-menu-available-mobile {
  border-top: 1px solid var(--white);
  padding-top: 15px;
  width: 80%;
  align-items: end;
  color: var(--white);
}
