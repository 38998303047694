/* style.css */

.my-input {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.my-input label {
  font-weight: bold;
  margin-bottom: 5px;
}

.my-input-content {
  all: unset;
  padding: 10px;
  font-size: 16px;
  transition: border-color 0.2s;
  border-bottom: 1px solid var(--background-black-color);
  background: transparent;
  position: relative;
  color: var(--black);
}

.my-input-content-area {
  resize: vertical;
  min-height: 60px;
}
