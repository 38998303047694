.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  min-height: 95vh;
  max-height: 98vh;
  padding: 20px 100px;
  gap: 30px;

  @media (max-width: 700px) {
    padding: 20px 20px;
    gap: 20px;
    min-height: 90vh;
  }
}

.container {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
}

.footer {
  display: flex;
  align-content: flex-start;
  color: var(--white);
  min-height: 100px;
  flex-wrap: wrap;
}

.footer p {
  line-height: 2;
}

.dark {
  color: var(--black);
}
