.container-mobile {
  flex: 1;
  background-color: var(--background-black-color);
  border-radius: 30px;
  border: 1px solid var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: auto;
  text-align: center;
  padding: 5vh 0px;
  width: 100%;
  gap: 30px;
}

.contact-text-main-mobile {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.text-small-mobile {
  font-size: 14px;
  color: var(--white);
}

.text-large-mobile {
  font-size: 30px;
  color: var(--white);
  font-family: "Oxygen";
}

.contact-links-mobile {
  display: flex;
  justify-content: center;
  gap: 20px;
  height: 20vh;
  align-items: flex-start;
  flex: 1;
  flex-direction: column;
}
