.login-popup {
  background-color: var(--background-white-color);
  border: 1px solid var(--white);
  border-radius: 10px;
  padding: 20px 20px 30px 20px;
  width: 300px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 5px var(--white);
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.login-popup h2 {
  font-size: 1.5rem;
}
