.add-element-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--white);
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  gap: 20px;
}

.add-element-description-container {
  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 100%;
}
